:root {
	--blue-main-color: #086CBA;
	--magenta-main-color: #AC0066;
	--yellow-main-color: #F5B200;
    --error-main-color: #d32f2f;
}

* {
	box-sizing: border-box;
}

html, body {
	margin: 0;
	padding: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

form {
	display: flex;
	flex-direction: column;
}

/* --------------------------------------------------------------------- */
/* -------------- REACT INTERNATIONAL PHONE CSS OVERRIDE --------------- */
/* --------------------------------------------------------------------- */

.react-international-phone-country-selector-button {
    height: 40px;
    padding-left: 4px;
    padding-right: 4px;
}

.react-international-phone-input {
    width: 100% !important;
    height: 40px !important;
    font-size: 16px !important;
}

.react-international-phone-error .react-international-phone-country-selector-button {
    border: 1px solid var(--error-main-color);
}

.react-international-phone-error .react-international-phone-input {
    border: 1px solid var(--error-main-color);
}

/* --------------------------------------------------------------------- */
/* --------------------------------------------------------------------- */
/* --------------------------------------------------------------------- */

.fs14 {
    font-size: 14px;
}

.fw500 {
    font-weight: 500;
}

.fdC {
	display: flex;
	flex-direction: column;
}

.ttU {
    text-transform: uppercase;
}

.linearGradientBackground {
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(22.5deg, rgba(67, 67, 67, 0.02) 0%, rgba(67, 67, 67, 0.02) 29%,rgba(47, 47, 47, 0.02) 29%, rgba(47, 47, 47, 0.02) 37%,rgba(23, 23, 23, 0.02) 37%, rgba(23, 23, 23, 0.02) 55%,rgba(182, 182, 182, 0.02) 55%, rgba(182, 182, 182, 0.02) 69%,rgba(27, 27, 27, 0.02) 69%, rgba(27, 27, 27, 0.02) 71%,rgba(250, 250, 250, 0.02) 71%, rgba(250, 250, 250, 0.02) 100%),linear-gradient(67.5deg, rgba(117, 117, 117, 0.02) 0%, rgba(117, 117, 117, 0.02) 14%,rgba(199, 199, 199, 0.02) 14%, rgba(199, 199, 199, 0.02) 40%,rgba(33, 33, 33, 0.02) 40%, rgba(33, 33, 33, 0.02) 48%,rgba(135, 135, 135, 0.02) 48%, rgba(135, 135, 135, 0.02) 60%,rgba(148, 148, 148, 0.02) 60%, rgba(148, 148, 148, 0.02) 95%,rgba(53, 53, 53, 0.02) 95%, rgba(53, 53, 53, 0.02) 100%),linear-gradient(135deg, rgba(190, 190, 190, 0.02) 0%, rgba(190, 190, 190, 0.02) 6%,rgba(251, 251, 251, 0.02) 6%, rgba(251, 251, 251, 0.02) 18%,rgba(2, 2, 2, 0.02) 18%, rgba(2, 2, 2, 0.02) 27%,rgba(253, 253, 253, 0.02) 27%, rgba(253, 253, 253, 0.02) 49%,rgba(128, 128, 128, 0.02) 49%, rgba(128, 128, 128, 0.02) 76%,rgba(150, 150, 150, 0.02) 76%, rgba(150, 150, 150, 0.02) 100%),linear-gradient(90deg, #ffffff,#ffffff);
}

.unauthenticated-backdrop {
    position: absolute;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
}

.flex-direction-column {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.text {
	overflow-wrap: break-word;
	word-break: break-word;
    white-space: pre-wrap;
}

.subtitle {
    font-weight: 500;
    font-size: 14px;
    color: gray;
}

.card-container {
	padding: 12px;
	border-radius: 8px;
	background-color: rgba(0, 0, 0, 0.08);
}

.dialog-title {
	text-align: center;
	margin-bottom: 16px;
    font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
}

.list-avatar {
	border-color: white;
	border-style: solid;
	border-width: 2px;
}

.pellet {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    color: white;
}

.table-td {
    padding: 8px;
    border: 1px solid lightgray;
	overflow-wrap: break-word;
	word-break: break-word;
    white-space: pre-wrap;
    font-size: 14px;
}

.list-item-container {
	flex: 1;
	overflow: auto;
	max-height: 240px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}

.list-item-button {
	flex-direction: column !important;
	justify-content: center !important;
	align-items: start !important;
}

.list-item-none {
	padding: 8px;
    background-color: rgb(230, 230, 230);
}

.internship-card-icon {
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 4px;
}

.menu-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 8px;
    background-color: white;
    border: 1px solid lightgray;
    transition: box-shadow 0.2s ease;
    cursor: pointer;
    text-decoration: none;
    color: unset;
    text-align: center;
    font-size: 16px;
}

    .menu-card:hover {
        border: none;
        box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
    }