.NavLink {
    text-decoration: none;
    color: unset;
}

.NavLink-selected > div {
    background-color: rgba(0, 0, 0, 0.08);
}

.Menu-version {
    padding: 12px;
    font-weight: 500;
    font-size: 14px;
    color: rgb(80, 80, 80);
}