.container {
    padding: 12px;
    font-size: 14px;
    background-color: #3c3c3c;
    color: white;
    text-align: center;
}

.legalNotice {
    color: white;
    text-decoration: none;
    margin-left: 2px;
}

    .legalNotice:hover {
        text-decoration: underline;
    }
