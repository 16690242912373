.container {
    flex: 1;
    display: flex;
}

.content {
    position: relative;
    flex: 1;
    background-image: url(../../assets/images/unauthenticated-layout/background.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    animation: fade-in-out 27s ease infinite 0s;
    opacity: 0;
}

@keyframes fade-in-out {

    0%, 41.67%, 100% {
        opacity: 0;
    }

    8.33%, 33.33% {
        opacity: 1;
    }
}

.backgroundInternship {
    background-image: url(../../assets/images/unauthenticated-layout/backgroundInternship.jpg);
    animation-delay: 0s;
}

.backgroundQuestionnaire {
    background-image: url(../../assets/images/unauthenticated-layout/backgroundQuestionnaire.jpg);
    animation-delay: 9s;
}

.backgroundAttendance {
    background-image: url(../../assets/images/unauthenticated-layout/backgroundAttendance.jpg);
    animation-delay: 18s;
}

.outlet {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    width: 100%;
    background-color: white;
}

.outlet > form {
    width: 100%;
}

.locale {
    position: absolute !important;
    top: 6px !important;
    right: 6px !important;
}

.title {
    margin: 48px;
    font-size: 26px;
    letter-spacing: 2px;
    color: rgb(60, 60, 60);
}

.subtitle {
    font-size: 16px;
}

.link {
    position: absolute;
    bottom: 24px;
    right: 24px;
}

.linkItem {
    padding: 8px 16px;
    border: 1px solid rgb(60, 60, 60);
    color: rgb(60, 60, 60);
    text-transform: uppercase;
    text-decoration: none;
}

.linkItem:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

@media (min-width: 900px) {

    .outlet {
        padding: 48px;
        min-width: 400px;
        width: 400px;
        max-width: 400px;
    }
}

@media (min-width: 1536px) {

    .title {
        font-size: 32px;
    }
}