.container {
    position: relative;
    z-index: 999;
    background-color: var(--magenta-main-color);
}

.sideItem {
    width: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuIcon {
    color: white;
}

.avatar {
    border: 2px solid white;
}
